import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import RelatedPagesTab from './RelatedPagesTab';

interface TabsBlock {
  id: string;
}

const TabsBlock: FC<TabsBlock> = (props) => {

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Связанные страницы',
      children: <RelatedPagesTab id={props.id} />,
    },
    {
      key: '2',
      label: 'Блоки',
      children: 'Блоки'
    },
    {
      key: '3',
      label: 'Изображения ',
      children: 'Изображения'
    }
  ];

  return (
    <Tabs defaultActiveKey={'1'} items={tabItems}/>
  );
};

export default TabsBlock;
